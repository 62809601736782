import { NextPage } from "next";

import { LoadingModule } from "@/components/modules/loading";
import { useGlobalContext } from "@/hooks/use-global-context";
import { DashboardUser } from "@/components/modules/dashboard-user";

import HomepageLayout from "@/components/layouts/homepage-layout";

import { useDefaultHomepage } from "@/hooks/use-default-homepage";
import { DEFAULT_HOMEPAGE_PAGES } from "@/constants/homepage";
import { OnboardingModule } from "@/components/modules/onboarding";
import { DashboardPageHeader } from "@/components/modules/dashboard-user/dashboard-page-header";

const LAYOUT_PARAMS = {
  navTitle: <DashboardPageHeader />,
  meta: {
    title: "Home - Glencoco",
  },
  pageContainerClassName: "bg-black/3",
  headerClassName: "!max-w-[1082px]",
  isBackgroundVisible: false,
};

const HomepageWithDataFetched = () => {
  const { isUserLoaded } = useGlobalContext();
  const { defaultPage } = useDefaultHomepage();

  return isUserLoaded ? (
    defaultPage === DEFAULT_HOMEPAGE_PAGES.ONBOARDING ? (
      <OnboardingModule />
    ) : (
      <DashboardUser />
    )
  ) : (
    <LoadingModule />
  );
};

const Homepage: NextPage = () => {
  return (
    <HomepageLayout {...LAYOUT_PARAMS}>
      <HomepageWithDataFetched />
    </HomepageLayout>
  );
};

export default Homepage;
