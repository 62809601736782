import {
  DEFAULT_HOMEPAGE_PAGES,
  DEFAULT_HOMEPAGE_PAGE_URL,
} from "@/constants/homepage";
import { useOnboarding } from "./use-onboarding";
import { useMemo } from "react";

export const useDefaultHomepage = () => {
  const { isOnboarded } = useOnboarding();

  const defaultPage: (typeof DEFAULT_HOMEPAGE_PAGES)[keyof typeof DEFAULT_HOMEPAGE_PAGES] =
    useMemo(
      () =>
        isOnboarded
          ? DEFAULT_HOMEPAGE_PAGES.DASHBOARD
          : DEFAULT_HOMEPAGE_PAGES.ONBOARDING,
      [isOnboarded]
    );

  return {
    defaultPage,
    defaultPageUrl: DEFAULT_HOMEPAGE_PAGE_URL[defaultPage],
  };
};
