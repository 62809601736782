import Router from "@/helpers/router";

export const DEFAULT_HOMEPAGE_PAGES = {
  PIPELINE: "pipeline",
  MARKETPLACE: "marketplace",
  DASHBOARD: "dashboard",
  ONBOARDING: "onboarding",
} as const;

export const DEFAULT_HOMEPAGE_PAGE_URL = {
  [DEFAULT_HOMEPAGE_PAGES.DASHBOARD]: Router.dashboard(),
  [DEFAULT_HOMEPAGE_PAGES.PIPELINE]: Router.accounts(),
  [DEFAULT_HOMEPAGE_PAGES.MARKETPLACE]: Router.marketplace(),
  [DEFAULT_HOMEPAGE_PAGES.ONBOARDING]: Router.onboarding(),
} as const;
